import React, { Fragment } from "react"

const StaticIntro = () => {
  return (
    <Fragment>
      <h1>Welcome to maxCode.online – my tech journal</h1>
      <p>
        Not a big surprise, I am just another techie who writes a blog about –
        well – tech stuff.
      </p>
      <p>
        As I have been doing programming and working with many different
        programming languages and frameworks, it’s inevitable that some, which
        get used less, just simply fade.
      </p>
      <p>I mean the syntax.</p>
      <p>
        This is why I tried a couple of tricks to make them stick, with more or
        less success.
      </p>
      <p>Mainly less…</p>
      <p>
        Tested and used Evernote, Google Keep, Microsoft OneNote – they all
        serve the same purpose and helped me a bit to keep track of my studies
        of programming languages, but I was never 100% happy. So there we go,
        let’s get back to the basics and write a blog style tech journal, that
        may help others as well.
      </p>
      <p>
        It’s certainly another trial to make some kind of a structured and well
        searchable information dump in different technologies. Make no mistake,
      </p>
      <p>
        I have high hopes that this blog will provide aid to other, like-minded
        technology fans as well.
      </p>
      <p>Happy coding.</p>
    </Fragment>
  )
}

export default StaticIntro
