import React from "react"

const Footer = () => {
  return (
    <footer className="my-12 text-center">
      © {new Date().getFullYear()} {" - "}
      <a
        href="https://maxCode.online"
        target="_blank"
        rel="noreferrer"
        title="tech stuff"
      >
        maxCode.online
      </a>
    </footer>
  )
}

export default Footer
